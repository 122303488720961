function checkDiscountValue () {
  discount = parseFloat($('#cart_item_discount').val())
  itemPrice = parseFloat($('#cart_item_price_without_tax').val())
  discountType = $('#cart_item_discount_type').val();
  if (discountType && discount) {
    switch (discountType) {
        case 'percent':
          if (discount >= 100) {
              $('#item-discount-error').html("Discount can't be greater or equal to 100%")
              $('#item-discount-error').show();
              $('#apply-discount').attr('disabled', 'disabled')
              setTimeout(()=> ($('#item-discount-error').hide()), 2000)
              break;
          }
          else {
              $('#item-discount-error').hide();
              $('#apply-discount').removeAttr('disabled')
              break;
          }
        case 'flat':
          if (discount >= itemPrice) {
              $('#item-discount-error').html("Discount can't be greater or equal to item price")
              $('#item-discount-error').show();
              $('#apply-discount').attr('disabled', 'disabled')
              setTimeout(()=> ($('#item-discount-error').hide()), 2000)
              break;
          }
          else {
              $('#item-discount-error').hide();
              $('#apply-discount').removeAttr('disabled')
              break;
          }
        default:
          break;
    }
  }
  else {
    $('#item-discount-error').hide();
  }
}
window.checkDiscountValue = checkDiscountValue;

function razorpayPos(){
  var card = $('#payment_mode_pos_card')
  var upi = $('#payment_mode_pos_upi')
  $('.timer-container').hide();
  if (card.is(':checked') || upi.is(':checked')){
    var paymentMode = card.is(':checked') ? 'CARD' : 'UPI'
    var _href = '/admin/pos_orders/send_payment_request_to_device';
    $("a.proceed-to-payment-btn").attr("href", _href + '?payment_mode='+paymentMode);
    $('.proceed-to-payment').show();
    $('.place-order').hide()
    $('.place-order').attr("disabled", true);
  }else{
    $('.proceed-to-payment').hide();
    $('.place-order').attr("disabled", false);
    $('.place-order').show();
  }
}
window.razorpayPos = razorpayPos;


function createCustomer(name, mobile){
  cart_id = $("#cart_id").val();
  $.ajax({
    url: '/admin/pos_orders/create_customer',
    method: 'post',
    dataType: 'script',
    data: {name: name, mobile_number: mobile, cart_id: cart_id}
  })
}
window.createCustomer = createCustomer;

function updateAttribute(el, attr_name) {
  var value = $(el).val()
  cart_id = $("#cart_id").val();
   $.ajax({
    url: '/admin/pos_orders/update_cart_attributes',
    method: 'patch',
    dataType: 'script',
    data: {attribute: attr_name, value: value, cart_id: cart_id}
  })
}
window.updateAttribute = updateAttribute;

function setGst(el, user_id) {
  var gstin = $(el).val()
  if (checksum(gstin)) {
    cart_id = $("#cart_id").val();
    $.ajax({
      url: '/admin/pos_orders/update_customer_attributes',
      method: 'patch',
      dataType: 'script',
      data: {attribute: 'gstin', value: gstin, user_id: user_id, cart_id: cart_id}
    })
  }
  else if (gstin === '') {
    return
  }
  else {
    $('.gst-err').show()
    setTimeout(function(){
      $('.gst-err').hide()
    }, 3000)
  }
}
window.setGst = setGst;

function checksum(g){
  //return true
  let regTest = /^([0][1-9]|[1-2][0-9]|[3][0-8])[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/.test(g)
  return regTest
}
window.checksum = checksum;

function checkQuantity(el, ev) {
  if($(".remove-discount-btn").length > 1){
    $(".cart-item-error").html("Please remove discount before making any changes.");
    $(".cart-item-error").slideDown();
    setTimeout(function() {
      $(".cart-item-error").slideUp();
    }, 4000);
    ev.preventDefault() 
    return;
  }
}
window.checkQuantity = checkQuantity;

function updateQuantity(el, item_id, item_type, cart_item_id) {
  if(!($(".remove-discount-btn").length > 1)){
    var quantity = $(el).val();
    cart_id = $("#cart_id").val();
    $.ajax({
      url: '/admin/pos_orders/update_item_quantity',
      method: 'put',
      dataType: 'script',
      data: {quantity: quantity, item_id: item_id, item_type: item_type, qty_input: true, cart_id: cart_id, cart_item_id: cart_item_id}
    })
  }  
}
window.updateQuantity = updateQuantity;

function updateOrderType(orderType, userId) {
  cart_id = $("#cart_id").val();
  $.ajax({
    url: '/admin/pos_orders/update_order_type',
    method: 'patch',
    dataType: 'script',
    data: {order_type: orderType, cart_id: cart_id}
  })
}
window.updateOrderType = updateOrderType;

function preventNegativeDiscount(ev) {
  if (ev.keyCode == 189 || ev.keyCode == 109) {
    ev.preventDefault();
  }
}
window.preventNegativeDiscount = preventNegativeDiscount;

function searchProduct(el) {
  var query = $(el).val()
  if (query.length > 2) {
    cart_id = $("#cart_id").val();
    $.ajax({
      url: '/admin/pos_orders/search_products',
      method: 'get',
      dataType: 'script',
      data: {query: query, cart_id: cart_id}
    })
  }
  else if (query.length == 0) {
    $('.item-list').show();
    $('.search-list').hide();
  }
}
window.searchProduct = searchProduct;


function searchCategories(el) {
  var query = $(el).val()
  if (query.length > 2) {
    cart_id = $("#cart_id").val();
    $.ajax({
      url: '/admin/pos_orders/search_categories',
      method: 'get',
      dataType: 'script',
      data: {query: query, cart_id: cart_id}
    })
  }
  else if (query.length == 0) {
    $('.all-categories').show();
    $('.filtered-categories').hide();
  }
}

window.searchCategories = searchCategories

function addProduct() {
  var input = $("#search-barcode-input").val() || $("#search-barcode-input-new").val() 
  if (input.length > 0) {
    cart_id = $("#cart_id").val();
    $.ajax({
      url: '/admin/pos_orders/add_item',
      method: 'post',
      dataType: 'script',
      data: {code: input, quantity: 1, focus_on_barcode: true, cart_id: cart_id}
    })
  } 
}
window.addProduct = addProduct;

function updateCustomerAttributes(el, attr_name, user_id) {
  var value = $(el).val()
  cart_id = $("#cart_id").val();
  $.ajax({
    url: '/admin/pos_orders/update_customer_attributes',
    method: 'patch',
    dataType: 'script',
    data: {attribute: attr_name, value: value, user_id: user_id, cart_id: cart_id }
  })
}
window.updateCustomerAttributes = updateCustomerAttributes

function setPosView() {
  var value = $("#pos-view").val()
  if(value == "slick"){
    $("#pos-product-block").removeClass("col-md-3 col-xl-3 col-lg-3");
    $("#pos-user-cart").removeClass("col-md-9 col-xl-9 col-lg-9");

    $("#pos-product-block").addClass("col-md-6 col-xl-6 col-lg-6");
    $("#pos-user-cart").addClass("col-md-6 col-xl-6 col-lg-6");
    
    const gridContainer = document.querySelector('.product-block-list');
    gridContainer.style.gridTemplateColumns = 'repeat(4, 1fr)';
    $(".extra-info").hide();
    $(".cart-summary .custom-fields").attr("colspan", "4");
    $(".cart-summary .cart-detail").attr("colspan", "3");
    
    $("#submit-order-section").removeClass("w-100");
    $("#submit-order-section").addClass("w-50");
  }
}
window.setPosView = setPosView




$(document).ready(function($) {
  $(document).on('keypress', '#order-form', function(){  
    if (event.key === 'Enter') {
        event.preventDefault();
    }
  });
  
	$(document).on('blur', '#mobile_number', function(){
    setTimeout(function(){
      var number = $('#mobile_number').val();
      addUser(number)
    }, 200)
	})

	$(document).on('blur', '#name', function(){
    var name = $(this).val()
    var mobileNumber = $('#mobile_number').val()
    if (name && mobileNumber) {
      createCustomer(name, mobileNumber)
    }
  })

  $(document).on('click', '#applyDiscount', function(){	 	
    var discount = $('#discount').val();
    var discountType = $('#discount_type').find(':selected').val();
    var discountcondition = $('#discount_tax_condition').find(':selected').val();
    cart_id = $("#cart_id").val();
    $.ajax({
      url: '/admin/pos_orders/apply_discount',
      method: 'post',
      dataType: 'script',
      data: {discount: discount, discount_type: discountType, cart_id: cart_id, discount_condition: discountcondition}
    })
  })

  $(document).on('click', '#applyCoupon', function(){   
    var coupon = $('#coupon').val();
    cart_id = $("#cart_id").val();
    $.ajax({
      url: '/admin/pos_orders/apply_coupon',
      method: 'post',
      dataType: 'script',
      data: {coupon_code: coupon, cart_id: cart_id}
    })
  })

  $(document).on('keydown', '#mobile_number', function(e) {
      if ($.inArray(e.keyCode, [46, 8, 9, 27, 13]) !== -1 || (e.keyCode == 65 && e.ctrlKey === true) || (e.keyCode >= 35 && e.keyCode <= 39)) {
          $('.error_msg').addClass('d-none');
          return;
      }
      if (e.keyCode == 17 || e.keyCode == 86){
        return
      }
      if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
          e.preventDefault();
          $('.error_msg').removeClass('d-none');
      }
  });


  $(document).on('keydown', '#discount', function(evt){	 		 	
    var keyCode = evt ? (evt.which ? evt.which : evt.keyCode) : event.keyCode;
    if(keyCode == 13){
      evt.preventDefault();
      $('#applyDiscount').click();
    }
  });

  $(document).on('click', 'input[name=payment_mode]', function(){
    razorpayPos();
  });

  $(document).on('click', '#payment_mode_pos_card', function(){	
    razorpayPos();
  });

  $(document).on('click', '.cart-clear-btn', function(){		
    $("#delete_modal").modal('show');
  })

  $(document).on("click", "#posCartModal", function(){
    if( $(".pos-side-bar").css("display") == "block"){
      $(".pos-side-bar").css("display", "none");
    }
  });

  $(document).on('click', ".pos_hamburger", function(){
    $('.pos-side-bar').slideToggle();
    if($("#posCartModal").hasClass("show")){
      $("#posCartModal").modal("hide");
    }
  });

  $(document).on("click", ".closeBtn", function() {
    $('#variant_box').remove()
  })
  
  /*$(document).on('submit', "#add_barcode_product", function(event){
    event.preventDefault();
    addProduct()
  })*/

  $(document).on('keypress', '#search-barcode-input', function(){  
    if (event.key === 'Enter') {
      addProduct()
    }
  });


  $(document).on('click', '#menu-toggle', function(e){
    e.preventDefault();
      var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);
        $("#toggleIcon").toggleClass("fa fa-angle-double-down fa fa-angle-double-up")
        $("#wrapper").toggleClass("toggled");
        let item = $('body').append("<div class='pos-sidebar-toggleed'></div>")
        if($("#wrapper").hasClass("toggled")){
          $("body").find('.pos-sidebar-toggleed').remove();
        }else{
          item
        }

        if(isIE11){
          if($("#wrapper").hasClass("toggled")){
          $('#sidebar-wrapper').css("margin-left", "-368px")
        }
        else {
          $('#sidebar-wrapper').css("margin-left", "-350px")  
        }  
      }
  });

  $(document).on('click', '#previousOrdersPos', function(e){
    e.preventDefault();
      var isIE11 = !!navigator.userAgent.match(/Trident.*rv\:11\./);
        $("#toggleIcon").toggleClass("fa-angle-double-down fa-angle-double-up");
        $("#previousOrderWrapper").toggleClass("toggled");
        
        let $this = $(this);
        if ($this.css('right') === '295px') {
            $this.css({'right': '-58px', 'transition': 'all 0.3s linear'});
            setTimeout(function(){
              $("#toggleIcon").removeClass("fa-angle-double-down");
              $("#toggleIcon").addClass("fa-angle-double-up");
            },50);
            
        } else {
            $this.css({'right': '295px', 'transition': 'all 0.3s linear'});
            setTimeout(function(){
              $("#toggleIcon").removeClass("fa-angle-double-up");
              $("#toggleIcon").addClass("fa-angle-double-down");
            },50);
        }

        let item = $('body').append("<div class='pos-sidebar-toggleed'></div>")
        if($("#previousOrderWrapper").hasClass("toggled")){
          $("body").find('.pos-sidebar-toggleed').remove();
        }else{
          item
        }

        if(isIE11){
          if($("#previousOrderWrapper").hasClass("toggled")){
          $('#previousSidebarWrapper').css("margin-right", "-368px");
        }
        else {
          $('#previousSidebarWrapper').css("margin-right", "-350px");
        }  
      }
  });
  
   $(document).on('click', 'body', function(e) 
    {
      var container = $("#wrapper") || $("#previousOrderWrapper");
      if (!container.is(e.target) && container.has(e.target).length === 0) 
      {
        container.addClass('toggled');
        $("body").find('.pos-sidebar-toggleed').remove();
      }
    });
  
  $(document).on('click', ".item-price-change-btn", function(){
    if($(".remove-discount-btn").length > 1){
      $(".cart-item-error").html("Please remove discount before making any changes.");
      $(".cart-item-error").slideDown();
      setTimeout(function() {
        $(".cart-item-error").slideUp();
      }, 4000);
      return;
    }
    item_id = $(this).data("id");
    item_amount = $(this).parent().parent().find("input").val();
    cart_id = $("#cart_id").val();
    $.ajax({
      url: '/admin/pos_orders/change_item_price',
      method: 'post',
      dataType: 'script',
      data: {cart_item_id: item_id, item_amount: item_amount, cart_id: cart_id}
    })
  })
  
  $(document).on('click', '.update-qty-btn', function(event){
    if($(".remove-discount-btn").length > 1){
      $(".cart-item-error").html("Please remove discount before making any changes.");
      $('.update-qty-btn').removeAttr('data-remote').removeAttr('href')
      $(".cart-item-error").slideDown();
      setTimeout(function() {
        $(".cart-item-error").slideUp();
      }, 4000);
      var url_link = $('.update-qty-btn').attr('href');
      $('.update-qty-btn').attr('data-href', url_link);
      $('.update-qty-btn').attr('href', "#");
      $('.update-qty-btn').removeAttr('data-remote');
      $('.update-qty-btn').removeAttr('data-method');

      event.preventDefault();
      return;
    }
  })

  $(document).on('change', '.switch-cart', function(event){
    cart_id = $(this).val();
    $.ajax({
      url: '/admin/pos_orders/switch_cart',
      method: 'post',
      dataType: 'script',
      data: {cart_id: cart_id}
    })
  })  

  $(document).on('click', '.remove-item-btn', function(event){
    if($(".remove-discount-btn").length > 1){
      $(".cart-item-error").html("Please remove discount before making any changes.");
      $('.remove-item-btn').removeAttr('data-remote').removeAttr('href')
      $(".cart-item-error").slideDown();
      setTimeout(function() {
        $(".cart-item-error").slideUp();
      }, 4000);
      var url_link = $('.remove-item-btn').attr('href');
      $('.remove-item-btn').attr('data-href', url_link);
      $('.remove-item-btn').attr('href', "#");
      $('.remove-item-btn').removeAttr('data-remote');
      $('.remove-item-btn').removeAttr('data-method');
      event.preventDefault();
      return;
    }
  })
  
  $(document).on("click", ".closeBtn", function (event) {
    $('#customfield-modal').modal('hide');
  });

  $(document).on("click", ".add-custom-fields", function () {
    $('#customfield-modal').modal('show');
  });

  $(document).on("click", "#customfield-modal a.add_fields", function () {
    var hidden_custom_fields = $("tr[style]").length
    var all_custom_fields =  $('.custom-fields').length
    if((all_custom_fields - hidden_custom_fields) == 3){
      $('#customfield-modal a.add_fields').hide();
    }else{
      $('#customfield-modal a.add_fields').show();
    }
  });

  $(document).on("click", "#customfield-modal a.trigger-remove", function () {
    $('#customfield-modal a.add_fields').show();
  });

})

$(document).ready(function() {
  $(document).on('click', function(event) {
    var $target = $(event.target);
    if(!$target.closest('.result-block').length && $('.result-block').is(":visible")) {
      $('.result-block').hide();
    }
  });
});

function addUser(number) {
  if (number.length !== 10) {
    $('.error_msg').text('Mobile must be 10 digits long.').removeClass('d-none');
    $('#mobile_number').val(number);
    $('div.result-block').hide();
    return;
  } else {
    $('.error_msg').addClass('d-none');
  }
  if(number == $("#user_phone").val()){
    $('#mobile_number').val(number);
    $('div.result-block').hide();
    return;
  }
  if (number.length == 10) {
    cart_id = $("#cart_id").val();

    $.ajax({
      url: '/admin/pos_orders/user_details',
      method: 'get',
      dataType: 'script',
      data: {mobile: number, cart_id: cart_id}
    })
  }
}

window.addUser = addUser;