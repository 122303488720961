// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"

// Import jQuery and jQuery UI
import 'jquery-ui/ui/widgets/sortable';

// Manually require rails_sortable
require("packs/rails_sortable.js");



require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// require('jquery')
require('cropperjs/dist/cropper.js')
require("@popperjs/core")

require("packs/jquery-3.2.1.min")
//require("packs/bootstrap_custom.js")
import Cropper from "cropperjs"
window.Cropper = Cropper

import 'select2/dist/css/select2.css'
import 'select2'

import './bootstrap_custom.js'
import 'cropperjs/dist/cropper.css'
import 'custom/custom_cropper.js'
import 'custom/companion.js'

require("packs/morris.min.js")
require("packs/jquery.slimscroll.min.js")
require("packs/app.js")
require("packs/chart.js")
require("packs/multiselect.min.js")
require("packs/bootstrap-datetimepicker.min.js")
require("packs/category/category.js")
require('./nested-forms/addFields')
require('./nested-forms/removeFields')

import jquery from 'jquery';
window.$ = window.jquery = jquery;
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.js';
require("packs/jquery.print.js");
require("packs/JsBarcode.code128.min.js");
require("packs/daterangepicker.js")
require("packs/pos.js")
require("packs/pos.js")
require("packs/appointment.js")

import 'slick-carousel';
import 'animate.css';
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


/*document.addEventListener("DOMContentLoaded", function () {
  new WOW.WOW().init();

  $(".slick-slider").slick({
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  });

});
*/

document.addEventListener('DOMContentLoaded', function() {
  var newposlink = document.getElementById('new_pos_order_link');
  
  // Check if the app is running in standalone mode or controlled by a service worker
  var isInStandaloneMode = ('standalone' in window.navigator && window.navigator.standalone) ||
                           (window.matchMedia('(display-mode: standalone)').matches) ||
                           (navigator.serviceWorker && navigator.serviceWorker.controller);

  if (isInStandaloneMode) {
    // The app is running as a PWA
    if (newposlink){
      newposlink.removeAttribute('target');
    }
     else {
      // The app is running in a web browser
      newposlink.setAttribute('target', '_blank');
    }
  }
});

$(document).ready(function(e) {
  
  if ($(window).width() < 767) {
    $("#navbarNav").addClass("sm-nav")
  }
  $(window).scroll(function(){
    if ($(this).scrollTop() > 80) {
      $('.nav-page-header').addClass('fixed');
    } else {
      $('.nav-page-header').removeClass('fixed');
    }
  });

  var regex = /^\d*[.]?\d*$/;  
  $(".mobile-num").on("input", function(){
    var inputVal = $(this).val();
    if(regex.test(inputVal)){
      $(this).removeClass("error")
      $(this).parent().parent().find(".error-msg").hide();
    } else{
      $(this).removeClass("success").addClass("error");
      $(this).parent().parent().find(".error-msg").show();
    }
  });

  $('body').tooltip({
      selector: '.iconTooltip'
  });

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })
  /* script for change file/logo in store setting */
  function readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        // logo
        $('#imagePreview').css('background-image', 'url('+e.target.result +')');
        $('#imagePreview').hide();
        $('#imagePreview').fadeIn(650);
      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  function signature(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function(e) {
        // signature
        $('#signaturePreview').css('background-image', 'url('+e.target.result +')');
        $('#signaturePreview').hide();
        $('#signaturePreview').fadeIn(650);

      }
      reader.readAsDataURL(input.files[0]);
    }
  }

  $("#imageUpload").change(function() {
    readURL(this);
  });
  $(".signature-upload").change(function() {
    signature(this);
  });

  $(function(){
    //store setting update logo
    $('[data-toggle="tooltip"]').tooltip()
    let image_url = $('#imagePreview').data('image');
    $('#imagePreview').css('background-image', 'url('+image_url+')');

    //store setting update signature
    let signature = $('#signaturePreview').data('image');
    $('#signaturePreview').css('background-image', 'url('+signature+')');

    //Regular print setting update signature
    let regular_print_setting_signature = $('#imageCropFileInputPreview').data('image');
     setTimeout(function(){$('#imageCropFileInputPreview').css('background-image', 'url('+regular_print_setting_signature+')');},200);
  });

  /* END of script for change file/logo in store setting */

  /*
  var sliderfor = $('.slider-for').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots:false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 3000,
    asNavFor: '.slider-nav'
  });
  
  var slidernav = $('.slider-nav').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: true,
    fade: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    appendDots:$(".custom-slick-dots")
  });

  $('.custom-slick-arrow-left').on('click', function () {    
    $('.slider-for').slick('slickPrev');
    $('.slider-nav').slick('slickPrev');
  });
  $('.custom-slick-arrow-right').on('click', function () {
    $('.slider-for').slick('slickNext');
    $('.slider-nav').slick('slickNext');
  });
  */
});


// Disable mousewheel on an input number field when in focus

$(document).on('focus', 'input[type=number]', function (e) {
  $(this).on('wheel.disableScroll', function (e) {
      e.preventDefault();
  });
});

// Remove the event listener when the input loses focus

$(document).on('blur', 'input[type=number]', function (e) {
  $(this).off('wheel.disableScroll');
});
